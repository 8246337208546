@import '../../assets/styles/colors';

.bc-no-stories-info {
  //position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;

  .title {
    font-family: Aksana, sans-serif;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $mint;
  }

  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: $black;
  }
}
