@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.bc-explore-story__container {
  position: relative;
  background-color: $white;
  // padding-bottom: 50px;

  .bc-explore-story__header-background-tablet {
    width: 100%;
    //height: 440px;
    overflow: hidden;

    position: relative;
    padding-bottom: 30px;
    padding-top: 72px;
    //top: -72px;

    .bc-explore-story__header-background-image {
      width: 100%;
      height: 100%;
      background-position: center center;
      object-fit: cover;
      background-size: cover;
      position: absolute;
      bottom: 0;
    }
    .bc-explore-story__header-background-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(to top, #000417 -7%, rgba(0, 0, 0, 0) 100%);
    }

    .bc-explore-story__title-block {
      position: relative;
      display: flex;
      flex-direction: column;
      color: $white;

      padding-top: 40px;

      .bc-explore-story__title-block-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .icon {
          svg {
            width: 9px;
            height: 12px;
          }
        }

        .label {
          font-family: Oswald, sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;

          margin-left: 7px;

          &.simple {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
          }
        }

        .avatar {
          height: 22px;
        }

        .calendar {
          svg {
            width: 16px;
            height: 16px;
          }
        }

        .title {
          max-width: 60vw;
          font-family: Oswald, sans-serif;
          font-size: 40px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          text-align: center;

          margin-top: 8px;
          margin-bottom: 29px;
        }
      }
    }
  }

  .bc-explore-story__content {
    margin-top: 0px;

    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: $black;

      text-align: left;
    }

    .bc-explore-story__image-wrapper {
      @include centralize;
      background-color: $white;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 2px solid $white;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      video {
        &:focus {
          outline: none;
        }
      }
    }
  }

  &.mint {
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $mint;
          }
        }
      }
    }
  }
  &.purple {
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $purple;
          }
        }
      }
    }
  }
  &.blue {
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
  }
}

