@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/mixins";

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.background {
  width: 100%;
  height: 100%;
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% - 2px), calc(-50% + 22px));
  cursor: pointer;
  svg:first-of-type {
    position: relative;
  }
  svg:last-of-type {
    position: absolute;
    inset: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    circle {
      fill: #46b777; //$orange;
    }
  }
  svg {
    transition: opacity 1.3s ease-out;
  }

  .text {
    @include centralize;
    text-align: center;
    width: 80vw;
    font-family: Bureau, sans-serif;
    font-size: 62px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 2px;
    color: $white; //$black;

    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 2px), calc(-50% + 84px));

    transition: text-shadow 0.3s ease-out;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  // &:before {
  //   content: '';
  //   height: 200px;
  //   width: 200px;
  //   background-color: #46b777;//$orange;
  //   border-radius: 50%;
  //   top: -40px;
  //   left: -30px;
  //   position: absolute;
  //   opacity: 0;
  //   transition: all 0.7s ease-out;
  //   filter: blur(20px);
  // }
  svg:first-of-type {
    opacity: 0.7;
  }
  &:hover {
    // & {
    svg:first-of-type {
      opacity: 0;
    }
    svg:last-of-type {
      opacity: 1;
    }
    &:before {
      opacity: 0.3;
      transform: scale(2);
    }
    .text {
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
    }
  }
  // &:hover {
  //     svg:first-of-type {
  //       opacity: 0.5;
  //     }
  //     svg:last-of-type {
  //       opacity: 0;
  //     }
  //     &:before {
  //       opacity: 0.3;
  //       transform: scale(2);
  //     }
  //     .text {
  //       text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  //     }
  //   }
}

// @media screen and (max-width: 768px) {
//   .background {
//     display: none;
//   }
//   .root {
//     min-height: 546px;
//     // background-image: url('../../../../assets/images/mmm/image-video/image-video-2.jpg');
//     background-position: center;
//   }
// }
