.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .sliders-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
     color: white;
  }
  .swiper-pagination-bullet-active {
    /* Styles de la balle active */
    background-color: white; /* Remplacez par la couleur souhaitée pour la balle active */
  }