@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-like {
  //@include centralize;
  @include no-highlight;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: $black;
  height: 18px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 16px;

    path {
      fill: $black;
    }
  }

  .icon {
    //height: 20px;
    display: flex;
    align-items: center;
  }

  .value {
    height: 18px;
    margin-left: 6px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    //line-height: 1.5;
    letter-spacing: normal;
    color: $black;
  }

  &.liked {
    &.mint {
      .value {
        color: $orange;
      }

      svg {
        path {
          fill: $orange;
        }
      }
    }

    &.purple {
      .value {
        color: $purple;
      }

      svg {
        path {
          fill: $purple;
        }
      }
    }

    &.blue {
      .value {
        color: $blue;
      }

      svg {
        path {
          fill: $blue;
        }
      }
    }
  }

  &.unavailable {
    //pointer-events: none;
    cursor: default;
  }
  &.disabled{
    color: #c91c1c;
    cursor: none;
  }
}

.bc-like-story {
  display: flex;
  align-items: center;
  //transform: translateY(-24px);
  user-select: none;

  .bc-like-story__control {
    @include centralize;
    @include no-highlight;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $black;
      }
    }

    >div:first-child {
      margin-right: 4px;
    }

    font-family: Source Sans Pro,
    sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: normal;
    color: $black;

    &.mint {
      &.liked {
        color: $mint;

        svg {
          path {
            fill: $mint;
          }
        }
      }
    }

    &.purple {
      &.liked {
        color: $purple;

        svg {
          path {
            fill: $purple;
          }
        }
      }
    }

    &.blue {
      &.liked {
        color: $blue;

        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
  }

  .bc-like-story__info {
    font-size: 16px;
    color: $dark-grey;
    margin-left: 12px;
  }

  &.unavailable {
    pointer-events: none;
  }
}

.same-category-already-liked{
  text-align: left;
    margin-bottom: 20px;
    font-size: 0.8em;
    color: #c91c1c;
    & > a{
      text-decoration: none;
    }
}