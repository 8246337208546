@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-text-area-container {
  margin: 0;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  position: relative;

  .bc-text-area-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid $alto;
    border-radius: 4px;
    background-color: $white;
    overflow: hidden;
    padding: 28px 16px 16px 16px;

    .label {
      position: absolute;
      top: 16px;
      left: 16px;
      //transform: translateY(-1px);

      transition: all 0.1s linear;

      user-select: none;

      z-index: 1;
    }

    textarea {
      position: relative;
      width: 100%;
      overflow: auto;
      outline: none;
      border: none;
      resize: none;
      background-color: $white;
      left: -4px;
      top: -2px;

      @include placeholder {
        color: $dark-grey;
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }

      &::-ms-clear {
        display: none;
      }
    }

    &.filled {
      border-color: $alto;
      .label {
        font-size: 12px;
        font-weight: 600;
        transform: translateY(-50%);
        color: $dark-grey;
      }
    }

    &.wrapper-focused {
      border-color: $wave;

      .label {
        font-size: 12px;
        font-weight: 600;
        transform: translateY(-50%);
        color: $wave;
      }
    }
  }

  .bc-text-area-status-message {
    @include ellipse-text;
    height: 24px;
    font-size: 14px;
    padding: 4px 12px;
    color: #000;

    width: 100%;

    &.error {
      background-color: $red;
    }

    &.success {
      background-color: $green;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.error {
    .bc-text-area-wrapper {
      border-color: $error;
    }
  }

  .bc-input__status {
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;

    height: 20px;
    margin: 2px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      @include centralize;
      &:first-child {
        margin-right: 4px;
      }
    }
  }

  .resize-handle {
    position: absolute;
    right: 0;
    top: 70px;
    cursor: ns-resize;

    width: 46px;
    height: 46px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    svg {
      width: 18px;
      height: 18px;
      line {
        stroke: $dark-grey;
        stroke-width: 2px;
      }
    }

    > div {
      pointer-events: none;
      height: 100%;
      width: 2px;
      background-color: $dark-grey;
    }
  }
}
