@import "../../assets/styles/colors";

.root {
  display: flex;
  flex-direction: column;
  // background-color: $alto-light;
  background-color: #fff;
}

.section {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}
