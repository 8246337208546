@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-text-editor__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  .bc-input__status {
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;

    height: 20px;
    margin: 2px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      @include centralize;

      &:first-child {
        margin-right: 4px;
      }
    }
  }

  .resize-handle {
    position: absolute;
    right: 2px;
    //top: 57px;
    cursor: ns-resize;
    z-index: 10;

    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      line {
        stroke: $dark-grey;
        stroke-width: 2px;
      }
    }

    > div {
      pointer-events: none;
      height: 100%;
      width: 2px;
      background-color: $dark-grey;
    }
  }
}

.bc-text-editor__container {
  position: relative;
  width: 100%;
  min-height: 56px;
  perspective: 400px;

  border: 1px solid $alto;
  border-radius: 4px;
  //transition: min-height 0.1s linear;

  &.filled {
    border-color: $alto;

    .bc-text-editor__label {
      color: $dark-grey;
      //font-weight: 600;
      transform: scale(0.8) translate(12px, 6px);
    }

    .bc-text-editor__toolbar {
      //opacity: 0;
    }
  }

  &.error {
    border-color: $error;
  }

  &.focused {
    border-color: $wave;
    min-height: 112px;

    .bc-text-editor__label {
      color: $wave;
      //font-weight: 600;
      transform: scale(0.8) translate(12px, 6px);
    }

    .bc-text-editor {
      //height: 100%;
    }

    .bc-text-editor__toolbar {
      transform: translateY(0);
      opacity: 1;
      height: 50px;
      padding-top: 10px;
      padding-bottom: 5px;
      //transition-delay: 0.1s;
    }
  }

  .bc-text-editor__label {
    position: absolute;
    transform: translate(16px, 16px);
    display: flex;
    align-items: center;
    transition: all 0.1s linear;
    z-index: 1;
  }

  .bc-text-editor {
    //position: relative;
    //border: 1px solid $alto;
    border: none;
    border-radius: 4px;
    padding-top: 22px;
    //height: 56px;

    &:focus-within {
      border-color: $wave;
    }
  }

  .bc-text-editor__toolbar {
    display: flex;
    transition: all 0.2s linear;
    height: 0;
    //padding-top: 0;
    //padding-bottom: 0;
    opacity: 0;
    //background-color: orange;
    overflow: hidden;

    border: none;

    transform: translateY(20px) rotateX(-35deg);
    margin-left: 16px;
  }

  .bc-text-editor__editor {
    max-height: 400px;
    min-height: 100px;
    overflow: auto;

    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    padding: 0 7px;
  }
}
