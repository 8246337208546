@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/mixins";

.root {
  // background-image: image-set(
  //                 url('../../../../assets/images/mmm/first-section-green/green.jpg') 1x,
  //                 url('../../../../assets/images/mmm/first-section-green/green@2x.jpg') 2x,
  //                 url('../../../../assets/images/mmm/first-section-green/green@3x.webp') 3x);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // padding: 24px;

  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  position: relative;
  // background-image: url('../../../../assets/images/2023/ultimate-founders-section/background-light.png');
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  width: 100%;
  height: 100%;
  // padding: 56px 0 0;
}
.isCompilingWrapper {
  // background-image: image-set(
  //                     url('../../../../assets/images/2023/compiling-phase/background@1x.jpg') 1x,
  //                     url('../../../../assets/images/2023/compiling-phase/background@2x.jpg') 2x,
  //                     url('../../../../assets/images/2023/compiling-phase/background@3x.jpg') 3x);
  background-image: url("../../../../assets/images/2023/compiling-phase/background-162@3x.jpg");
}

.title {
  position: relative;
  font-family: Bureau, sans-serif;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -2px;
  text-align: center;
  color: #000;
  text-transform: uppercase;

  // margin-top: 116px;
  transition: all 0.3s ease-out;

  font-size: 102px;

  @media screen and (max-width: 1400px) {
    font-size: 90px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 80px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70px;
  }
  @media screen and (max-width: 768px) {
    font-size: 52px;
  }
}

.textWrapper {
  position: relative;
  margin: 20px 0 50px;

  p {
    text-align: center;
    font-family: Calibri, sans-serif;
    font-size: 18px;
    white-space: break-spaces;
    color: #000;
    line-height: 1.4;
    margin-top: -4px;
  }
}
.rfcSubSection {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  padding: 86px 32px;
  //
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-image: url("../../../../assets/images/2025/ufc-background.jpg");

  @media screen and (max-width: 1200px) {
    background-image: url("../../../../assets/images/2025/ufc-background-mob.jpg");
    background-size: 100% 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 42px 24px 24px;
  }

  .ufcTextWrapper {
    // display: flex;
    // flex-direction: column;

    .textTitle {
      font-family: "SouthCoast";
      width: 100%;
      text-align: center;
      color: #e42895;
      line-height: 0.7em;
      font-size: 108px;
      @media screen and (max-width: 1200px) {
        font-size: 90px;
      }
      @media screen and (max-width: 870px) {
        font-size: 48px;
      }
    }
    p.ufcText {
      margin: 0 14%;
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 24px;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 468px) {
        font-size: 18px;
        margin: 0 4%;
      }
    }
    p.textNote {
      font-weight: bold;
      font-style: italic;
      margin-top: 12px;

      .textEmph {
        color: #e42895;
      }
    }
  }
}

.control {
  margin-bottom: 86px;
}

.hovered {
  .wrapper {
    &:before {
      opacity: 1;
    }
  }
  .title {
    background: rgb(240, 129, 80);
    background: radial-gradient(
      circle,
      rgba(240, 129, 80, 1) 0%,
      rgba(238, 83, 106, 1) 100%
    );
    //background-image: image-set(
    //                url('../../../../assets/images/mmm/first-section-green/green.webp') 1x,
    //                url('../../../../assets/images/mmm/first-section-green/green@2x.webp') 2x,
    //                url('../../../../assets/images/mmm/first-section-green/green@3x.webp') 3x);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .hoveredButton {
    > div:first-of-type {
      transition: all 0.3s ease-out;
      background-color: $orange;
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    padding: 0;
    background-image: none;
  }
  .wrapper {
    padding: 0;
  }
  // .title {
  //   margin-top: 54px;
  //   font-size: 52px;
  //   text-align: center;
  //   line-height: 0.89;
  //   letter-spacing: -1.07px;
  //   justify-content: center;
  //   align-items: center;
  //   display: flex;
  // }

  .textWrapper {
    margin: 20px 0;
    p {
      text-align: center;
    }
  }
}

//@media screen and (max-width: 768px) {
//  .title {
//    margin-top: 54px;
//    //font-size: 80px;
//    text-align: center;
//    line-height: 0.89;
//    letter-spacing: -1.07px;
//    justify-content: center;
//    align-items: center;
//    display: flex;
//  }
//
//  .textWrapper {
//    margin: 20px 0;
//    p {
//      text-align: center;
//    }
//  }
//}

.buttonWrapper {
  text-align: center;
  margin-top: 30px;
}
.threeImages {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.threeImagesSection {
  background-size: 100% 100%;
  width: 100%;
  padding: 86px 32px 52px;
  //
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../assets/images/2025/3fs/3-FS-background.jpg");
  @media (min-width: 1200px) {
    padding: 86px 10%;
  }
  @media (max-width: 768px) {
    background-image: url("../../../../assets/images/2025/3fs/3-FS-background-mob.jpg");
  }
}
.tagImgWrap {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 34%;
  padding-bottom: 55%;
  position: relative;
  margin: -44px -10px;
}
.tag1ImgWrap {
  flex-basis: 40%;
}
.tag2ImgWrap {
  flex-basis: 40%;
}
.tag3ImgWrap {
  flex-basis: 40%;
}
.tagImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: -2%;
}
.tag1Img {
  // background-image: url("../../../../assets/images/2025/banners/fearless-banner.png");
  background-image: url("../../../../assets/images/2025/3fs/3-FS-fearless.png");
  // top: 3px;
}
.tag2Img {
  // background-image: url("../../../../assets/images/2025/banners/family-banner.png");
  background-image: url("../../../../assets/images/2025/3fs/3-FS-family.png");
}
.tag3Img {
  // background-image: url("../../../../assets/images/2025/banners/founders-banner.png");
  background-image: url("../../../../assets/images/2025/3fs/3-FS-founders.png");
}
.footerCollage {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  background-image: url("../../../../assets/images/2025/footer-collages/footer-collages-background.jpg");
  padding-top: 20%;
  width: 100%;

  &.votingFooter {
    padding-top: 45%;
    @media (max-width: 768px) {
      padding-top: 220% !important;
      background-image: url("../../../../assets/images/2025/nominations/nominations-background-v0.jpg");
      // ba ckground-image: url("../../../../assets/images/2025/ufc-background-mob.jpg");
      background-size: cover;
    }
  }
  &.selectionPhase.votingFooter {
    padding-top: 45%;
    @media (max-width: 768px) {
      padding-top: 142% !important;
      background-image: url("../../../../assets/images/2025/nominations/nominations-background-v0.jpg");
      // ba ckground-image: url("../../../../assets/images/2025/ufc-background-mob.jpg");
      background-size: cover;
    }
  }
  .footerActions {
    position: absolute;
    top: 5%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    // gap: 32px;
    a {
      width: 24%;
      aspect-ratio: 1 / 1;
      position: relative;
      @media (max-width: 768px) {
        width: 60%;
      }
    }
    .submitPicsImg,
    .requestSongImg,
    .downloadAssetsImg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .submitPicsImg {
      background-image: url("../../../../assets/images/2025/voting/submit-pics.png");
    }
    .requestSongImg {
      background-image: url("../../../../assets/images/2025/voting/request-a-song.png");
      margin-top: 25%;
      // flex-shrink: 1;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    .downloadAssetsImg {
      background-image: url("../../../../assets/images/2025/voting/download-assets.png");
    }
  }
  .footerSelectionPhaseActions {
    position: absolute;
    top: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @media (max-width: 768px) {
      // flex-direction: column;
      flex-wrap: wrap;
    }
    & > * {
      width: 20%;
      aspect-ratio: 1 / 1;
      position: relative;
      @media (max-width: 768px) {
        width: 45%;
      }
    }
    :nth-child(2),
    :nth-child(4) {
      @media (min-width: 769px) {
        margin-top: 5%;
      }
    }

    .submitPicsImg,
    .requestSongImg,
    .downloadAssetsImg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .submitPicsImg {
      background-image: url("../../../../assets/images/2025/voting/submit-pics.png");
    }
    .requestSongImg {
      background-image: url("../../../../assets/images/2025/voting/request-a-song.png");
      margin-top: 25%;
      // flex-shrink: 1;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    .downloadAssetsImg {
      background-image: url("../../../../assets/images/2025/voting/download-assets.png");
    }
  }

  //
  .footerRightCollage,
  .footerLeftCollage {
    position: absolute;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25%;
  }
  .footerLeftCollage {
    left: 8px;
    aspect-ratio: 639 / 400;
    background-image: url("../../../../assets/images/2025/footer-collages/footer-collage-left2.png");
  }
  .footerRightCollage {
    right: 0;
    aspect-ratio: 640 / 428;
    background-image: url("../../../../assets/images/2025/footer-collages/footer-collage-right.png");
  }
  .downloadAssets {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90%;
    aspect-ratio: 398 / 388;
    background-image: url("../../../../assets/images/2025/footer-collages/footer-download-assets-1.2.png");
    z-index: 1;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .footerCollage {
    padding-top: 40%;

    .footerRightCollage,
    .footerLeftCollage {
      width: 50%;
    }
  }
}
@media (max-width: 768px) {
  .threeImages {
    flex-direction: column;
    gap: 100px;
  }
  .tagImgWrap {
    width: 100%;
    padding-bottom: 100%;
  }
  .tagImg {
    margin: -60px;
  }
  .tag1Img {
    top: 15px;
  }
  .tagImgWrap {
    margin: 0;
  }
  .buttonWrapper {
    padding-top: 40px;
  }
}

.compilingPhaseImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.compilingPhaseImages:last-child {
  margin-bottom: 150px;
}
// .compilingImgWrap {
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   width: 33%;
//   // padding-bottom: 34%;
//   position: relative;
// }
a.compilingImgWrap {
  cursor: pointer;
}
// .compilingImgWrap:hover {
//   box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
// }
// .compilingImgWrap:active {
//   box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
// }

// @media (max-width: 768px) {
//   .compilingImgWrap {
//     width: 50%;
//     padding-bottom: 50%;
//   }
// }
// @media (max-width: 568px) {
//   .compilingImgWrap {
//     width: 70%;
//     padding-bottom: 70%;
//   }
// }

.tX8u6G_spectrum-Image-img {
  display: none !important;
}

.clickable {
  cursor: pointer;
}
.compilingPhaseImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: -4%;
}
.compilingPhaseImgRadio {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_radio.png");
}
.compilingPhaseImgScrapbook {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_scrapbook.png");
}
.compilingPhaseImgPosters {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_posters.png");
}
.compilingPhaseImgUFC {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_UFC.png");
}
.compilingPhaseImgUFCRevealedSoon {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_UFC_revealed_soon.png");
}
.compilingPhaseImgQuest {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_quest.png");
}
.compilingPhaseImgAssets {
  background-image: url("../../../../assets/images/2023/compiling-phase/v2_assets_download.png");
}

.selectionPhaseImgScrapbook {
  background-image: url("../../../../assets/images/2025/selection-phase/scrapbook.png");
}
.selectionPhaseDownloadAssets {
  background-image: url("../../../../assets/images/2025/selection-phase/asset-download.png");
}
.selectionPhaseImgRadio {
  background-image: url("../../../../assets/images/2025/selection-phase/legacy-radio-on.png");
}
.selectionPhaseImgUFC {
  background-image: url("../../../../assets/images/2025/selection-phase/UFC.png");
}
// .selectionPhaseImgQuest {
//   background-image: url("../../../../assets/images/2025/selection-phase/v3_quest.png");
// }

.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Style the iframe to occupy 100% width, 100% height, and have a max-width of 100% */
.popupContainer iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border: none; /* Remove border if not needed */
}

.legacyRadioOn {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url("../../../../assets/images/2023/selection-phase/legacy-radio-playing.gif");
  background-size: cover;
  background-position: center;
}
