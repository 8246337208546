@import "../../../assets/styles/colors";

.root {
}

.periodLabel {
  font-family: Oswald, sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: #000;
  margin-top: 32px;
}
.dark .periodLabel {
  color: #000 !important;
}

.moreContentButton {
  margin-top: 24px;
  > div:first-of-type {
    background-color: $blue;
    box-shadow: inset 0 0 0 2px $white;
  }
}

.periodSection {
  margin-bottom: 64px;
}

@media screen and (max-width: 768px) {
  .periodLabel {
    font-size: 42px;
  }
}
