@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-explore-story__container {
  position: relative;
  background-color: $white;
  // padding-bottom: 50px;
  min-height: calc(100vh - 107px);
  //padding-left: calc(100vw - 100%);
  //display: flex;
  //align-items: flex-start;
  //justify-content: center;

  .bc-explore-story__header-background {
    width: 100%;
    //height: 440px;
    overflow: hidden;

    position: relative;
    padding-bottom: 30px;
    padding-top: 72px;
    top: -72px;

    .bc-explore-story__header-background-image {
      width: 100%;
      height: 100%;
      background-position: center center;
      object-fit: cover;
      background-size: cover;
      position: absolute;
    }
    .bc-explore-story__header-background-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(to top, #000417 -7%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .bc-explore-story__header-rank-badge {
    position: absolute;
    height: 40px;
    right: 40px;
    bottom: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 17px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba($black, 0.5);

    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;

    .badge-icon {
      margin-right: 4px;
    }

    svg {
      width: 22px;
    }

    @media screen and (max-width: 869px) {
      bottom: unset;
      right: unset;
      width: 106px;
      height: 26px;
      padding: 0 10px;
      position: relative;
      margin: 20px auto 0 auto;

      .badge-icon {
        svg {
          width: 12px;
        }
      }

      .badge-label {
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }

  .bc-explore-story__title-block {
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;

    padding-top: 112px;

    .bc-explore-story__title-block-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &.column {
        flex-direction: column;
      }

      &.mar-19 {
        margin-top: 19px;
      }

      .bc-explore-story__title-block-row_wrapper {
        align-items: center;
        display: flex;
      }

      .inline {
        display: flex;
      }

      .center {
        align-items: center;
      }

      .icon {
        svg {
          width: 9px;
          height: 12px;
        }
      }

      .label {
        font-family: Oswald, Arial, sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;

        margin-left: 7px;

        &.important {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          font-size: 18px;
        }

        &.simple {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
        }

        &.nominate {
          font-family: Source Sans Pro, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: $white;
          margin: 0;
          max-width: 55vw;
        }
      }

      .avatar {
        height: 22px;
      }

      .cheers {
        svg {
          width: 22px;
          height: 22px;
        }
      }

      .calendar {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .title {
        max-width: 60vw;
        font-family: Oswald, sans-serif;
        font-size: 72px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: center;

        margin-top: 8px;
        margin-bottom: 29px;
      }
    }
  }

  .bc-explore-story__content {
    display: block;
    position: relative;
    margin-top: 0;

    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: $black;

      text-align: left;
    }

    .bc-explore-story__image-wrapper {
      @include centralize;
      background-color: $white;
      width: 100%;
      height: 600px;
      overflow: hidden;
      border: 2px solid $white;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      video {
        //height: 100%;
        //max-width: 100%;
        margin: 0 auto;
        &:focus {
          outline: none;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $alto;
      }

    }
    .sticky-like {
      z-index: 100;
      height: 50px;
      display: flex;
      align-items: center;
      transition: background-color 0.1s linear;
      transform: translate3d(0,0,0);

      &.overlay {
        background-color: $mid-grey;
      }
    }
  }

  &.mint {
    .bc-explore-story__header-background {
      .bc-explore-story__header-background-overlay {
        //background-image: linear-gradient(to top, $mint -40%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $mint;
          }
        }
      }
    }
    .bc-explore-story__header-rank-badge {
      color: $mint;
      svg {
        path {
          fill: $mint;
        }
      }
    }
  }
  &.purple {
    .bc-explore-story__header-background {
      .bc-explore-story__header-background-overlay {
        //background-image: linear-gradient(to top, $purple -40%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $purple;
          }
        }
      }
    }
    .bc-explore-story__header-rank-badge {
      color: $purple;
      svg {
        path {
          fill: $purple;
        }
      }
    }
  }
  &.blue {
    .bc-explore-story__header-background {
      .bc-explore-story__header-background-overlay {
        //background-image: linear-gradient(to top, $blue -40%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .bc-explore-story__title-block {
      .icon {
        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
    .bc-explore-story__header-rank-badge {
      color: $blue;
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
}

.bc-formatted-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: $black;

  text-align: left;

  margin: 12px 0;
}

.explore-story__footer-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 170px;
  opacity: 0.5;
  pointer-events: none;

  background-image: linear-gradient(
                  to bottom,
                  hsla(0, 0%, 0%, 0) 0%,
                  hsla(0, 0%, 0%, 0.018) 13%,
                  hsla(0, 0%, 0%, 0.038) 25%,
                  hsla(0, 0%, 0%, 0.06) 35.9%,
                  hsla(0, 0%, 0%, 0.086) 45.9%,
                  hsla(0, 0%, 0%, 0.117) 54.8%,
                  hsla(0, 0%, 0%, 0.155) 62.8%,
                  hsla(0, 0%, 0%, 0.2) 70%,
                  hsla(0, 0%, 0%, 0.255) 76.3%,
                  hsla(0, 0%, 0%, 0.32) 81.8%,
                  hsla(0, 0%, 0%, 0.396) 86.5%,
                  hsla(0, 0%, 0%, 0.486) 90.5%,
                  hsla(0, 0%, 0%, 0.59) 93.8%,
                  hsla(0, 0%, 0%, 0.709) 96.5%,
                  hsla(0, 0%, 0%, 0.846) 98.5%,
                  hsl(0, 0%, 0%) 100%
  );
}
