@import "../../../assets/styles/mixins";

/* autoprefixer grid: autoplace */

.bc-files-upload__preview-wrapper {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-template-areas:
    "img-1 img-2 img-2"
    "img-1 img-3 img-4"
    "img-5 img-5 img-6"
    "img-7 img-8 img-6";

  .bc-file-upload__preview {
    //margin: auto;
    @include centralize;

    width: 100%;
    height: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 8px;
    border: 2px solid #eee;

    .close-preview {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: #000;
      border-radius: 50%;
      transition: opacity 0.1s linear;
      cursor: pointer;
      opacity: 1;
      box-shadow: 0px 0px 10px rgba(239, 238, 234, 0.8);
      /* Ambre avec ombre */
    }

    // &:hover {
    //   .close-preview {
    //     opacity: 1;
    //   }
    // }

    video {
      width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-play {
      position: absolute;
      width: 32px;
      height: 32px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $dark-grey;
        }
      }
    }

    &.mobile {
      .close-preview {
        opacity: 1 !important;
      }
    }
  }

  .img-1 {
    grid-area: img-1;
    min-height: 11rem;
  }

  .img-2 {
    grid-area: img-2;
  }

  .img-3 {
    grid-area: img-3;
  }

  .img-4 {
    grid-area: img-4;
  }

  .img-5 {
    grid-area: img-5;
  }

  .img-6 {
    grid-area: img-6;
    min-height: 11rem;
  }

  .img-7 {
    grid-area: img-7;
  }

  .img-8 {
    grid-area: img-8;
  }
}
