@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-y: auto;
  z-index: 1000000000000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding: 60px;

  background-color: rgba($black, 0.68);
  backdrop-filter: blur(3px);

  @include no-highlight;

  &.mobile {
    padding: 8px;
  }

  .modal__content {
    max-width: 100%;
    position: relative;
    background-color: white;
    z-index: 10002;
    min-width: 200px;
    width: 552px;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 12px;

      background-image: linear-gradient(269deg, #f2874f 44%, #e50594);
    }

    .modal__close-wrapper {
      position: absolute;
      top: -34px;
      right: -8px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 24px;
      text-transform: uppercase;
      cursor: pointer;

      .icon {
        height: 23px;
        margin-right: 6px;

        svg {
          path {
            fill: $white;
          }
        }
      }

      .label {
        font-family: Oswald, sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 1px;
        color: $white;
      }
    }

    &.sm {
      width: 500px;
    }

    &.md {
      width: 636px;
    }

    &.lg {
      width: 900px;
    }

    &.xl {
      width: 1100px;
    }
    &.fullscreen {
      height: 100%;
      width: 100%;
    }
    &.radio {
      // height: 70px;
      // width: 100%;
      width: 500px;
    }
  }
}
