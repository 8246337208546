@import '../../assets/styles/colors';

.copyright {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $white;
  margin-top: 4px;
  //padding-bottom: 20px;
}

.dark {
  .copyright {
    color: $black
;  }
}
