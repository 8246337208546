@import "../../../../assets/styles/colors";

.root {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // padding-top: 60px;
  z-index: 1;
  height: 100%;
}

.background {
  position: absolute;
  inset: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../../../assets/images/2025/header-section/header-background.jpg");
  // background-image: image-set(
  //                 url('../../../../assets/images/2023/header-section/header-background@1x.jpg') 1x,
  //                 url('../../../../assets/images/2023/header-section/header-background@2x.jpg') 2x,
  //                 url('../../../../assets/images/2023/header-section/header-background@3x.jpg') 3x);

  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  overflow: auto;
}

.row {
  position: relative;
}

.textWrapper {
  margin: 24px 0 40px;

  p {
    font-family: Calibri, sans-serif;
    font-size: 20px;
    color: $white;
    text-align: left;
    white-space: break-spaces;
    transition: all 0.3s ease-out;
  }
}

.titleWrapper {
  width: fit-content;

  .title:nth-child(2) {
    transform: translateY(-24px) !important;
  }
  .title:nth-child(3) {
    transform: translateY(-48px) !important;
  }
}

.title {
  font-family: Bureau, sans-serif;
  font-size: 150px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -2px;
  color: $white;
  text-transform: uppercase;
  width: fit-content;
  transition: all 0.3s ease-out;
}

.logo {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  svg {
    width: 69px;
    height: 69px;
    path {
      transition: all 0.3s ease-out;
      fill: $white;
    }
  }
}

.hovered {
  &:after {
    background-color: $orange;
    filter: blur(35px);
  }
  .background {
    &:before {
      opacity: 1;
    }
  }
  .title {
    background: rgb(240, 129, 80);
    background: radial-gradient(
      circle,
      rgba(240, 129, 80, 1) 0%,
      rgba(238, 83, 106, 1) 100%
    );
    background-clip: text;
    background-position: top right;
    -webkit-background-clip: text;
    color: transparent;
  }
  .hoveredButton {
    > div:first-of-type {
      background-color: $orange;
    }
  }

  .textWrapper {
    p {
      color: $black;
    }
  }

  .logo {
    svg {
      path {
        fill: $black;
      }
    }
  }
}
.containerW {
  z-index: 1;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.headerLockupW {
  width: 63%;
  padding-bottom: 48%;
  position: relative;
  margin-top: 3%;
  margin-right: -26%;
  margin-left: -7%;
}
.headerImageW {
  flex-basis: 65%;
  padding-bottom: 48%;
  position: relative;
  margin-top: 3%;
  margin-right: -7%;
  margin-left: -8%;
}
@media only screen and (max-width: 1300px) {
  .headerImageW {
    margin-top: 52px;
  }
}

.headerLockup,
.headerImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.headerLockup {
  background-image: url("../../../../assets/images/2025/header-section/header-Logo.png");
}
.headerImage {
  background-image: url("../../../../assets/images/2025/header-section/header-collage.png");
  &.votingPhase {
    background-image: url("../../../../assets/images/2025/voting/header-collage.png");
  }
}
@media (max-width: 768px) {
  .container {
    // flex-direction: column;
    margin-top: 80px;
  }
  .headerLockupW {
    width: 63%;
    padding-bottom: 52%;
    position: relative;
    margin-top: 2%;
    margin-right: -45%;
    margin-left: -7%;
  }
  .headerImageW {
    flex-basis: 76%;
    padding-bottom: 59%;
    position: relative;
    margin-top: -4%;
    margin-right: -7%;
    margin-left: -8%;
  }
}

@media screen and (max-width: 768px) {
  .title {
    width: 100%;
    font-size: 80px;
    text-align: center;
    line-height: 0.89;
    letter-spacing: -1.07px;
  }

  .titleWrapper {
    width: 100%;

    .title:nth-child(2) {
      transform: translateY(0) !important;
    }
    .title:nth-child(3) {
      transform: translateY(0) !important;
    }
  }

  .textWrapper {
    margin: 29px 0;
    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 375px) {
  .titleWrapper {
    width: 100%;
    .title {
      width: 100%;
      text-align: center;
    }
  }
}
