#pagination-container {
  align-items: center;
  display: flex;
  justify-content: center;

  button {
    background: #fff; //transparent;
    border: 1px solid #e50594;
    border-radius: 4px;
    color: #e50594;
  }

  button:hover {
    background: #e50594;
    color: #fff;
  }

  button:focus {
    outline: none;
  }

  .manage-button {
    background: #fff; //transparent;
    border: 1px solid #e50594;
    border-radius: 4px;
    color: #e50594;
    // font-family:SourceSans;
    font-weight: 600;
    font-size: 16px !important;
    height: 32px !important;
    padding: 0px 15px !important;
    margin-right: 8px;
    min-width: 20px;
    text-align: center;
    user-select: none;
    width: auto !important;
  }

  .manage-button:hover {
    background: #e50594;
    color: #fff;
  }

  .manage-button:active {
    background: #e50594;
  }

  .btn-page.btn-page--numbers {
    display: flex;
    margin: 0 15px;
  }

  .btn-page>* {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    // font-family:SourceSans;
    font-weight: 600;
    font-size: 16px;
    height: 32px;
    justify-content: center;
    padding: 0;
    margin-right: 8px;
    width: 32px;
  }

  .btn-page>*:last-child {
    margin-right: 0px;
  }

  .btn-page>*[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  .btn-page--numbers .is-active {
    background: #e50594;
    color: #fff;
  }

  .btn-page .ellipsis {
    color: #e50594;
  }

  .btn-page svg {
    height: 24px;
    width: 24px;
  }

  .btn-page .ellipsis svg path {
    fill: 000s;
  }
}