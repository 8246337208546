@import '../../assets/styles/colors';

.root {
  display: flex;
  flex-direction: column;

  padding: 24px;

  background-color: $white;
}

.item {
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 0.92;

  margin: 24px 0;
  text-align: center;

  text-decoration: none;
  text-transform: uppercase;

  color: $black;

  &:first-of-type {
    margin-top: 0;
  }
}