@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-input,
.bc-search-input {
  position: relative;
  width: 100%;
  height: 56px;
  $border-color: $alto;

  .bc-input__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $white;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 16px;

    font-family: Source Sans Pro, sans-serif;

    .label,
    .label-placeholder {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $black;

      width: 90%;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .label-placeholder {
      visibility: hidden;
    }

    .label {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(18px, 14px);
      transform-origin: left;

      transition: all 0.1s linear;

      user-select: none;
    }

    input {
      width: 100%;
      //display: none;
      position: relative;
      left: -2px;
      top: -2px;
      bottom: 0;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $black;

      outline: none;
      border: none;
      background: transparent;
    }

    &.filled {
      border-color: $border-color;
      .label {
        transform: scale(0.8) translate(18px, 4px);
        color: $dark-grey;
      }
      input {
        display: block;
        color: $black;
      }
    }

    &.error {
      border-color: $error;
    }

    &.focused {
      border-color: $wave;
      .label {
        transform: scale(0.8) translate(18px, 4px);
        color: $wave;
      }
      input {
        display: block;
      }
    }
  }

  .bc-input__status {
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;

    height: 20px;
    margin: 2px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      @include centralize;
      &:first-child {
        margin-right: 4px;
      }
    }
  }

  &.readOnly {
    pointer-events: none;
  }
}
