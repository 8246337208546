@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-button {
  position: relative;
  @include centralize;
  @include no-highlight;
  height: 64px;
  border-radius: 32px;
  //background-color: $mint;
  font-family: Oswald, Arial, sans-serif;
  //overflow: hidden;
  white-space: nowrap;
  //overflow: hidden;
  text-transform: uppercase;

  user-select: none;
  cursor: pointer;

  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: $white;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    // height: 40px;
    background: rgba(0, 0, 0, 0.3);
    filter: blur(20px);
    transform: translate3d(0, 0, 0);
  }

  padding: 12px 24px;

  .bc-button_background {
    transition: filter 0.1s ease-out;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: $purple;
    border-radius: 32px;
    pointer-events: none;
    background-color: #f1874f;
    border-radius: 15px;
  }

  .label {
    z-index: 0;
    font-size: 24px;
  }

  .shadow {
    position: absolute;
    top: 26px;
    width: 176px;
    height: 25px;
    opacity: 0.56;
    filter: blur(19px);
    background-color: $purple;
    z-index: -1;
  }
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 36px;
  //  width: 176px;
  //  height: 35px;
  //  opacity: 0.56;
  //  filter: blur(19px);
  //  background-color: $mint;
  //}

  svg {
    position: absolute;
    top: -56px;
    left: -77%;
    z-index: -1;
  }

  &.auto {
    width: 100%;
  }

  &.xs {
    width: 80px;
  }

  &.sm {
    width: 120px;
  }

  &.md {
    width: 160px;
  }

  &.lg {
    width: 200px;
  }

  &.xl {
    width: 240px;
  }

  &.secondary {
    color: $purple;
    border: 1px solid $purple;
    .bc-button_background {
      background-color: $white;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default !important;
    &:hover {
      filter: brightness(100%);
    }

    &:active {
      filter: brightness(100%);
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  //&:hover {
  //  filter: brightness(102%);
  //  box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
  //}

  &:active,
  &:hover {
    .bc-button_background {
      filter: brightness(90%);
    }
  }
}

.bc-button.newDesign {
  background-color: $orange;
  & .shadow {
    background-color: $orange;
  }
  & .bc-button_background {
    background-color: $orange;
    border-radius: 15px;
  }
  & .label {
    font-size: 3em;
  }
}

#home-page {
  .bc-button_background {
    background-color: $orange;
    border-radius: 15px;
  }
  .bc-button .shadow {
    background-color: $orange;
  }
  .bc-button {
    // height: 75px;
    display: inline-flex;
    width: auto;
  }
  .bc-button .label {
    font-size: 3em;
  }
}
.hoveredButton {
  > div:first-of-type {
    transition: all 0.3s ease-out;
    background-color: $orange;
  }
}
