.filterWrapper {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 2em;
  margin: 60px 0 10px;
}

.winnerFilterW {
  display: flex;
  align-items: center;
  gap: 5px
}

.winnerFilterLabel {}