@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

$minimized-navbar-height: 72px;
$noSearch-navbar-height: 100px;

.bc-navbar-mobile {
  $transitionMini: all 0.2s ease-out;
  // @include no-highlight;
  // position: fixed;
  // top: 0;
  // width: 100%;
  // height: 170px;
  // z-index: 10000000;
  // transform: translateZ(0);
  // background-color: transparent;
  // transition: $transitionMini;
  // overflow: hidden;

  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  padding-left: calc(100vw - 100%);

  height: 73px;
  z-index: 1000000;

  box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.26);
  background-color: rgba(255, 255, 255, 0.8); //$white;
  transition: all 0.2s linear;

  .bc-logo__wrapper {
    //margin-top: 24px;
    display: flex;
    transition: $transitionMini;
    transform-origin: left center;
    transform: scale(1.2);
    height: 100%;
    align-items: center;

    .icon {
      width: 56px;
      height: 56px;
      transition: $transitionMini;
    }

    svg {
      transition: $transitionMini;
      width: 56px;
      height: 56px;
      path {
        fill: $black;
      }
    }

    .label {
      font-family: Oswald, sans-serif;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      margin-left: 12px;
      white-space: nowrap;
      transition: $transitionMini;

      .primary {
        color: $black;
        font-size: 24px;
        line-height: 1.3;
        transition: $transitionMini;
      }

      .secondary {
        color: $mint;
        font-size: 20px;
        line-height: 1;
        transition: $transitionMini;
      }
    }
  }

  .bc-navbar-row {
    height: 102px;
    transition: $transitionMini;

    .bc-navbar__content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }

  &.filterOpened {
    height: 120px;
  }

  &.noSearch {
    height: $noSearch-navbar-height;
    .bc-navbar-row {
      height: $noSearch-navbar-height;
    }
  }

  &.minimized {
    height: $minimized-navbar-height;
    // box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.26);
    &.filterOpened {
      box-shadow: none;
    }
    .bc-navbar-row {
      height: $minimized-navbar-height;
    }
    .bc-logo__wrapper {
      transform: scale(1);
    }
  }

  &.transparent {
    box-shadow: none;
    background-color: transparent;
  }

  &.expandedVisible {
    box-shadow: none;
  }

  .bc-icon-button {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: $black;
    height: 100%;

    .icon {
      height: 24px;
      transform: translateY(-1px);
    }

    .label {
      font-family: Bureau, sans-serif;
      height: unset;
    }

    svg {
      path {
        fill: $black;
      }
    }
  }

  &.light {
    .bc-icon-button {
      color: $white;
    }
    svg {
      path {
        fill: $white;
      }
    }
    .bc-logo__wrapper {
      .label {
        .primary {
          color: $white;
        }
      }
    }
  }

  &.wizard {
    pointer-events: all;
    box-shadow: none;
    border-bottom: 1px solid $grey;
    overflow: visible;

    .bc-wizard-step__wrapper {
      display: flex;
      width: 100%;

      position: absolute;
      top: 100%;
      left: 0;

      .step {
        flex: 1;
        height: 3px;

        &.active {
          background-image: linear-gradient(
            269deg,
            #f2874f 44%,
            #e50594,
            #f2874f
          );
        }
      }
    }

    .bc-wizard-status__wrapper {
      width: 100%;
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      //background-color: $white;

      .bc-wizard-status {
        display: flex;
        .bc-wizard-status__item {
          flex: 1;
          display: flex;
          font-family: "Source Sans Pro", sans-serif;

          div {
            display: flex;
            align-items: center;
          }
          &.left {
            cursor: pointer;
            justify-content: flex-start;
            svg {
              transform: rotate(-90deg);
            }
            font-size: 14px;
          }
          &.center {
            justify-content: center;
            font-size: 16px;
            white-space: nowrap;
          }
          &.right {
            justify-content: flex-end;
            font-size: 14px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
