@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.tagsContainer {
  position: relative;
  flex: 1;
  padding-right: 24px;
  overflow: hidden;
}

.tagsWrapper,
.tagsMeasure {
  margin: 0;
}

.tagsWrapper {
  align-items: center;
  position: relative;
  display: flex;
  left: 0;
  list-style: none;
  padding: 0;
  flex-wrap: nowrap;

  justify-content: flex-end;
}

.tagsMeasure {
  left: 0;
  top: 0;
  position: absolute;
  background-color: red;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  > li {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.moreTags {
  //margin-left: auto;
  > button {
    height: 30px;
    width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    background-color: $peach;

    &:hover {
      filter: brightness(80%);
    }

    > ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        margin: 1px;
        list-style: none;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $black;
      }
    }
  }
}

.tagsPanel {
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  list-style: none;
  display: flex;
  background: white;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
  &:before {
    content: '';
    position: absolute;
    right: 40px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid $white;
  }
}

.tag {
  @include ellipse-text;
  padding: 8px;
  background: $peach;
  border-radius: 2px;
  color: $black;
  font-family: Calibri, sans-serif;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}