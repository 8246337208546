@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

/* autoprefixer grid: autoplace */

.best-stories__container {
  padding-top: 73px;
  min-height: calc(100vh - 167px);
  margin-bottom: 60px;

  .title {
    font-family: Bureau, sans-serif;
    font-size: 150px;
    font-weight: 500;
    line-height: 0.87;
    letter-spacing: -2px;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    padding-top: 86px;
  }

  .description {
    font-family: Source Sans Pro, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $white;

    padding-top: 1rem;
    margin-bottom: 60px;

    font-size: 22px;
    @media (max-width: 767px) {
      font-size: 18px;
      padding: 0 24px;
    }
  }

  .tabs {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    padding-top: 1rem;

    .tab,
    .tab-underline {
      @include no-highlight;
      font-family: Oswald, sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $black;
      text-transform: uppercase;
      cursor: pointer;
      min-height: 32px;
      margin: 0 3%;
      transition: border-color 0.2s;

      &.active {
        color: $mint;
      }
    }

    .tab-underline {
      min-height: 3px;
      height: 3px;
      border-bottom: 3px solid transparent;
      span {
        visibility: hidden;
      }
      &.active {
        border-color: $mint;
      }
    }
  }

  .list {
    //padding-top: 1rem;
    display: grid;

    grid-column-gap: 24px;
    //grid-row-gap: 24px;

    grid-template-columns: repeat(3, minmax(360px, 1fr));
    grid-template-rows: repeat(50, auto);

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(320px, 1fr));
      grid-template-rows: repeat(50, auto);
      grid-column-gap: 24px;
      //grid-row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(260px, 1fr));
      grid-template-rows: repeat(50, auto);
      grid-column-gap: 24px;
      //grid-row-gap: 24px;
    }
  }

  .bc-no-stories-info {
    margin-top: 3rem;
  }

  @media screen and (max-width: 767px) {
    .title {
      font-size: 8px;
      padding: 5rem 1rem 1rem;
    }
    .description {
      margin-bottom: 24px;
    }
    .tabs {
      padding-top: 32px;
    }
  }
}

.background {
  background-image: image-set(
    url("../../assets/images/mmm/frames/third/layer-11-copy-2-3.jpg") 1x,
    url("../../assets/images/mmm/frames/third/layer-11-copy-2-3@2x.jpg") 2x,
    url("../../assets/images/mmm/frames/third/layer-11-copy-2-3@3x.jpg") 3x
  );
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
