@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";

/* autoprefixer grid: autoplace */

.best-stories__list {
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;

  grid-column-gap: 24px;

  grid-template-columns: repeat(3, minmax(360px, 1fr));
  grid-template-rows: repeat(50, auto);

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    grid-template-rows: repeat(50, auto);
    grid-column-gap: 24px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(260px, 1fr));
    grid-template-rows: repeat(50, auto);
    grid-column-gap: 24px;
  }
}

.ufcTextWrapper {
  // display: flex;
  // flex-direction: column;

  p {
    margin: 0;
  }
  .textTitle {
    font-family: "SouthCoast";
    width: 100%;
    text-align: center;
    color: #e42895;
    line-height: 0.7em;
    font-size: 108px;
    margin-top: 24px;

    @media screen and (max-width: 1200px) {
      font-size: 90px;
    }
    @media screen and (max-width: 870px) {
      font-size: 48px;
    }
  }
  p.ufcText {
    // margin: 0 14%;
    text-align: center;
    font-size: 32px;
    @media screen and (max-width: 1200px) {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 468px) {
      font-size: 18px;
      margin: 0 4%;
    }
  }
  p.textNote {
    font-weight: bold;
    font-style: italic;
    margin-top: 12px;

    .textEmph {
      color: #e42895;
      text-transform: uppercase;
      // text-decoration: underline;
    }
  }
}
.best-stories__container {
  padding-top: 73px;
  min-height: calc(100vh - 167px);
  margin-bottom: 60px;

  .title {
    font-family: Bureau, sans-serif;
    font-size: 80px;
    font-weight: 500;
    line-height: 0.87;
    letter-spacing: -2px;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    padding-top: 86px;
  }

  .description {
    font-family: Source Sans Pro, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $white;

    padding-top: 1rem;
    margin-bottom: 60px;

    font-size: 22px;
    @media (max-width: 767px) {
      font-size: 18px;
      padding: 0 24px;
    }
  }

  .tabs {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    padding-top: 1rem;

    .tab,
    .tab-underline {
      @include no-highlight;
      font-family: Oswald, sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $black;
      text-transform: uppercase;
      cursor: pointer;
      min-height: 32px;
      margin: 0 3%;
      transition: border-color 0.2s;

      &.active {
        color: $mint;
      }
    }

    .tab-underline {
      min-height: 3px;
      height: 3px;
      border-bottom: 3px solid transparent;

      span {
        visibility: hidden;
      }

      &.active {
        border-color: $mint;
      }
    }
  }

  .list {
    //padding-top: 1rem;
    display: grid;

    grid-column-gap: 24px;
    //grid-row-gap: 24px;

    grid-template-columns: repeat(3, minmax(360px, 1fr));
    grid-template-rows: repeat(50, auto);

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(320px, 1fr));
      grid-template-rows: repeat(50, auto);
      grid-column-gap: 24px;
      //grid-row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(260px, 1fr));
      grid-template-rows: repeat(50, auto);
      grid-column-gap: 24px;
      //grid-row-gap: 24px;
    }
  }

  .bc-no-stories-info {
    margin-top: 3rem;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 48px;
      padding: 5rem 1rem 1rem;
    }

    .description {
      margin-bottom: 24px;
    }

    .tabs {
      padding-top: 32px;
    }
  }
}

.bc-best-story-item {
  position: relative;
  //height: 376px;
  width: css-clamp(275px, 100%, 475px);

  background-color: $white;
  //border: 1px solid $alto-light;
  //box-shadow: 0 28px 44px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  //overflow: hidden;
  cursor: pointer;
  @include no-highlight;
  transition: transform 0.3s ease-out;

  &:hover {
    .header-row-background-image {
      transform: scale(1.02);
    }

    .header-row-background-overlay {
      opacity: 1 !important;
    }
  }

  .bc-best-story-item__row {
    position: relative;

    &.header-row {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 241px;

      .header-row-background-image {
        position: absolute;
        width: 101%;
        height: 101%;
        object-fit: cover;
        background-size: cover;
        transition: transform 0.7s ease-out;
        will-change: transform;
      }

      .header-row-background-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(238, 238, 238, 0),
          #000000 124%
        );
        transition: opacity 0.3s linear;
        opacity: 0.7;
      }

      .header-row-content__wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        padding: 16px;
      }
    }

    &.content-row {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      min-height: 92px;
    }

    &.footer-row {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      height: 49px;

      //border-top: 1px solid $grey;
      //transform: translateY(-8px);

      &.simplified {
        border: none;
        height: 0;
      }
    }
  }

  .story-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    margin-top: 3px;
    color: $white;

    text-transform: uppercase;
  }

  .author {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    grid-area: author;
    width: 100%;
    height: 100%;
    margin-top: 4px;

    span {
      height: 20px;

      &:last-child {
        margin-left: 3px;
        font-family: Source Sans Pro, sans-serif;
        font-size: 12px;
        line-height: 1.8;
        color: $white;
        height: 100%;
        //max-height: 3rem;
        white-space: nowrap;
        @include ellipse-text;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      padding-top: 2px;

      path {
        fill: $white;
      }
    }
  }

  .location {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    height: 100%;

    span {
      height: 20px;
      margin-right: 8px;
      font-family: Oswald, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: 1px;
      color: $white;
      text-transform: uppercase;
      white-space: nowrap;
      @include ellipse-text;
    }

    svg {
      width: 10px;
      height: 18px;
      padding-top: 4px;

      path {
        fill: $orange;
      }
    }
  }

  .like {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-area: like;
    padding: 0 24px;
    height: 100%;
    box-sizing: border-box;

    .bc-like {
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      path {
        fill: $emperor;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -8;
    left: 0;
    right: 0;
    height: 8px;
    background-image: linear-gradient(269deg, #f2874f 44%, #e50594);
  }
}

.best-stories__background {
  background-image: url("../../../assets/images/2025/background-other-pgs.jpg");
  // background-image: url('../../../assets/images/2023/page-background.png');
  // background-image: image-set(url('../../../assets/images/mmm/wizard/layer-2-2.jpg') 1x,
  //     url('../../../assets/images/mmm/wizard/layer-2-2@2x.jpg') 2x,
  //     url('../../../assets/images/mmm/wizard/layer-2-2@3x.jpg') 3x);
  background-position: top;
  background-repeat: repeat-y;
  background-size: 100% auto;
  height: 100%;
  width: 100%;
}
