@import '../../assets/styles/colors';

.bg {
  pointer-events: none;

  svg {
    path {
      stroke: $grey;
    }
  }
}

.bg-path-1 {
  animation: wave;
  animation-iteration-count: infinite;
  animation-duration: 40s;
  animation-timing-function: ease-in-out;
}

@keyframes wave {
  0% {
    transform: rotate(0) scale(0.7);
  }
  50% {
    transform: rotate(15deg) scale(0.7);
  }
  75% {
    transform: rotate(0) scale(0.7);
  }
  100% {
    transform: rotate(0) scale(0.7);
  }
}

.bg-path-2 {
  animation: wave2;
  animation-iteration-count: infinite;
  animation-duration: 60s;
  animation-timing-function: ease-in-out;
}

@keyframes wave2 {
  0% {
    transform: rotate(0) scale(0.7);
  }
  50% {
    transform: rotate(20deg) scale(0.7);
  }
  75% {
    transform: rotate(-5deg) scale(0.7) translate(-10%, -10%);
  }
  100% {
    transform: rotate(0) scale(0.7);
  }
}

.bg-path-3 {
  animation: wave3;
  animation-iteration-count: infinite;
  animation-duration: 80s;
  animation-timing-function: ease-in-out;
}


@keyframes wave3 {
  0% {
    transform: rotate(0) scale(0.7);
  }
  50% {
    transform: rotate(-10deg) scale(0.7);
  }
  75% {
    transform: rotate(12deg) scale(0.7);
  }
  100% {
    transform: rotate(0) scale(0.7);
  }
}
