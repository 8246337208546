@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-loader__container {
  @include centralize;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: $white;
  opacity: 0.68;
  z-index: 1;

  &.animated {
    animation-name: zoom;
    animation-duration: 4s;
    transform-origin:50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .bc-loader__logo {
    svg {
      path {
        fill: $white;
      }
    }
    &.animated {
      animation-name: zoom;
      animation-duration: 4s;
      transform-origin:50% 50%;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  @keyframes zoom {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  @keyframes spaceboots {
    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
  }
  @keyframes heartbeat {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
    10% { transform: matrix3d(1, 0, 0, 0, 0.1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
    20% { transform: matrix3d(1, 0, 0, 0, -0.1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
    40% { transform: matrix3d(1, 0, 0, 0, 0.05, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
    50% { transform: matrix3d(1, 0, 0, 0, -0.05, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) }
  }

  .bc-loader__path {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50%;
    height: 50%;

    &.path1 {
      background-image: url('../../assets/images/loader-bg/path1.svg');
      top: 0;
      left: 0;
      width: 388px;
      height: 513px;
    }
    &.path2 {
      background-image: url('../../assets/images/loader-bg/path2.svg');
      bottom: 0;
      left: 0;
      width: 73px;
      height: 323px;
    }
    &.path3 {
      background-image: url('../../assets/images/loader-bg/path3.svg');
      bottom: 0;
      left: 0;
      width: 307px;
      height: 123px;
    }
    &.path4 {
      background-image: url('../../assets/images/loader-bg/path4.svg');
      bottom: 0;
      right: 0;
      width: 761px;
      height: 281px;
    }
    &.path5 {
      background-image: url('../../assets/images/loader-bg/path5.svg');
      top: 0;
      right: 0;
      width: 357px;
      height: 381px;
    }
    &.path6 {
      background-image: url('../../assets/images/loader-bg/path6.svg');
      top: 0;
      right: 0;
      width: 537px;
      height: 186px;
    }
  }
}

.bc-upload-progress__container {
  @include centralize;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .bc-upload-progress-background {
    opacity: 0.95;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 0;
  }

  .bc-upload-progress-content {
    position: relative;

    .bc-upload-progress-content__row {
      margin-bottom: 24px;
    }

    .bc-upload-progress-logo {
      font-family: Bureau, sans-serif;
      font-size: 80px;
      font-weight: 500;
      line-height: 0.87;
      letter-spacing: -2px;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    .bc-loader__progress {
      position: relative;
      width: 100%;
      height: 56px;
      //margin-top: 24px;
      background-color: $white;
      border: 2px solid $white;
      border-radius: 28px;
      //overflow: hidden;

      .bc-loader__progress-bar {
        position: relative;
        height: 100%;
        width: 0;
        background: linear-gradient(90deg, rgba(247,181,0,1) 0%, rgba(182,32,224,1) 50%, rgba(50,197,255,1) 100%);
        transition: width 0.4s ease-out;
        border-radius: 28px;

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(90deg, rgba(247,181,0,1) 0%, rgba(182,32,224,1) 50%, rgba(50,197,255,1) 100%);
          filter: blur(20px);
        }
      }
      .bc-loader__progress-value {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include centralize;
        font-size: 20px;
        font-weight: 500;
        font-family: Source Sans Pro, sans-serif;
        color: $white;

        &.colorful {
          color: $purple;
        }
      }
    }

    .bc-upload-progress-text {
      font-family: Source Sans Pro, sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }
}

@media screen and (max-width: 768px) {
  .bc-upload-progress__container {
    .bc-upload-progress-content {
      .bc-upload-progress-logo {
        font-size: 70px;
      }
    }
  }
}

.bc-background-wizard {
  position: absolute;
  background-image: image-set(
                  url('../../assets/images/mmm/wizard/layer-2-2.jpg') 1x,
                  url('../../assets/images/mmm/wizard/layer-2-2@2x.jpg') 2x,
                  url('../../assets/images/mmm/wizard/layer-2-2@3x.jpg') 3x
  );
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bc-background-wizard__overlay {
  position: absolute;
  background-image: url('../../assets/images/mmm/map.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2;
}
