@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-dropdown-select {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 56px;

  .bc__status {
    position: absolute;
    top: 100%;
    left: 0;
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: 000;

    height: 20px;
    margin: 2px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      @include centralize;
      &:first-child {
        margin-right: 4px;
      }
    }
  }
}

.bc-dropdown-select__container {
  position: relative;
  flex: 1;
  border-radius: 4px;
  border: solid 1px $white;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: $white;

  &.open,
  &.focused {
    //border-color: $wave;
  }

  .bc-dropdown-select__control {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    background-color: $white;
    padding: 0 12px;
    //border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;

    &.open {
      border-radius: 2px 2px 0 0;
      .bc-dropdown-select__label {
        color: $wave;
      }
    }

    > div:first-child {
      width: 100%;
      padding-right: 12px;
      text-align: left;
      transform: translate(4px, 6px);

      user-select: none;
      @include ellipse-text;
    }

    .control-toggle {
      &.open {
        transform: rotate(180deg) translateY(4px);
      }
    }

    .bc-dropdown-select__input {
      transform: translateY(6px);
      width: 100%;
      border: none;
      outline: none;
    }
  }

  .bc-dropdown-select__label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(18px, 14px);
    transform-origin: left;

    transition: all 0.1s linear;

    user-select: none;
  }

  .bc-dropdown-select__options {
    width: calc(100% + 2px);
    left: -1px;
    min-height: 57px;
    max-height: 360px;

    display: none;
    flex-direction: column;
    align-items: flex-start;

    background-color: $white;
    border-radius: 0 0 4px 4px;

    position: absolute;
    top: calc(100% - 4px);
    z-index: 12;

    overflow-x: hidden;

    &.open {
      display: block;
      border-color: $white;
    }

    .bc-dropdown-select__option {
      font-size: 16px;
      padding: 15px 12px;
      width: 100%;
      height: 57px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;

      border-bottom: 1px solid $mid-grey;

      &:hover {
        background-color: $light-grey;
      }

      &.selected {
        background-color: $light-grey;
        color: $mint;
        user-select: none;
        //cursor: default;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.editable {
    pointer-events: all;
  }

  &.hasSelected {
    .bc-dropdown-select__label {
      transform: scale(0.8) translate(18px, 4px);
      color: $dark-grey;
    }
  }

  &.error {
    border-color: $error;
  }

  &.focused {
    .bc-dropdown-select__label {
      transform: scale(0.8) translate(18px, 4px);
      color: $dark-grey;
    }
  }
}
