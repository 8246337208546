@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import 'colors';
@import 'mixins';
@import 'layout';

html {
  box-sizing: border-box;
  //scroll-behavior: smooth;
}

html.wf-loading * {
  opacity: 0;
}

html,
body {
  height: 100vh;
  width: 100vw;
  background-color: $bg-color;

  margin: 0;
  padding: 0;

  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1rem;
  font-weight: 400;

  color: rgb(0, 0, 0);

  //overflow-x: hidden;

  touch-action: manipulation;
}

body {
  position: relative;
}

#root {
  // height: 100%;
  //overflow-x: hidden !important;
  //overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: $white;
  padding-left: calc(100vw - 100%);
}

* {
  box-sizing: border-box;
}

button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0
}