@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-navbar {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  padding-left: calc(100vw - 100%);

  height: 73px;
  z-index: 1000000;

  box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.26);
  background-color: rgba(255, 255, 255, 0.8); //$white;
  transition: all 0.2s linear;

  @include no-highlight;

  .bc-logo__wrapper {
    //width: 10rem;
    // height: 73px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 32px;

    .icon {
      width: 56px;
      height: 56px;
    }

    svg {
      width: 56px;
      height: 56px;

      path {
        fill: $black;
      }
    }

    .label {
      font-family: Oswald, sans-serif;
      font-weight: 500;
      height: 56px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      margin-left: 12px;
      white-space: nowrap;

      .primary {
        color: $black;
        font-size: 24px;
        line-height: 1.3;
      }

      .secondary {
        color: $mint;
        font-size: 20px;
        line-height: 1;
      }
    }
  }

  .bc-navbar__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &.transparent {
    box-shadow: none;
    background-color: transparent;
    // color: $white;

    .bc-navbar-row {
      //background-color: transparent;
    }
  }

  &.expandedVisible {
    box-shadow: none;
  }

  &.filterOpened {
    transition-duration: 0s;
    box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.26);
    background-color: $white;
  }

  .bc-icon-button {
    color: $black;
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;

    > span {
      //height: 24px;
      //line-height: 1.4;
      font-family: Oswald, sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 1px;
      opacity: 0.8;
    }

    svg {
      transform: translateY(2px);

      path {
        fill: $black;
      }
    }
  }

  &.light {
    .bc-icon-button {
      color: $white;
    }

    svg {
      path {
        fill: $white;
      }
    }

    .bc-logo__wrapper {
      .label {
        .primary {
          color: $white;
        }
      }
    }

    .bc-nav-button {
      .bc-nav-button__content {
        color: $white;
      }
    }

    .bc-app-intro-toggle {
      color: $white;
    }
  }

  &.wizard {
    pointer-events: all;
    display: flex;
    align-items: center;
    height: 74px;
    box-shadow: none;
    border-bottom: 1px solid $grey;

    .bc-wizard-step__wrapper {
      display: flex;
      width: 100%;

      position: absolute;
      top: 100%;
      left: 0;
      height: 3px;

      .step {
        flex: 1;
        height: 3px;

        &.active {
          background-image: linear-gradient(
            269deg,
            #f2874f 44%,
            #e50594,
            #f2874f
          );
        }
      }
    }

    .bc-wizard-status__wrapper {
      width: 100%;
      height: 50px;
      //background-color: $white;
      position: absolute;
      top: calc(100% + 1px);
      left: 0;

      .bc-wizard-status {
        display: flex;

        .bc-wizard-status__item {
          flex: 1;
          display: flex;
          font-family: "Source Sans Pro", sans-serif;

          div {
            display: flex;
            align-items: center;
          }

          &.left {
            cursor: pointer;
            justify-content: flex-start;

            svg {
              transform: rotate(-90deg);
            }

            font-size: 14px;
          }

          &.center {
            justify-content: center;
            font-size: 16px;
          }

          &.right {
            justify-content: flex-end;
            font-size: 14px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.subheader-background {
  position: fixed;
  top: 72px;
  z-index: 1;
  will-change: opacity;
  width: 100%;
  height: 50px;
  background-color: $mid-grey;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;

  &.visible {
    opacity: 1;
  }
}
