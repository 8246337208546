@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-status-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 68px;
  position: relative;

  background-color: $white;
  border: 1px solid $mid-grey;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.08);

  margin: 12px 0;

  .status-message__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 17px;

    svg {
      flex: 0 0 24px;
    }

    div {
      margin-left: 10px;
      text-align: left;

      opacity: 0.4;
      font-family: Source Sans Pro, sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $black;

      @include ellipse-text;
      white-space: nowrap;
    }
  }

  .status-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    &.error {
      background-color: $error;
    }
    &.success, &.info {
      background-color: $mint;
    }
  }
}
