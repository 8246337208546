.root {
  > div {
    width: 100% !important;
    height: 100% !important;
    > div {
      position: relative;
      padding-bottom: 56.25%;
      height: 0 !important;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.root.isRadio {
  > div {
    > div {
      padding-bottom: 83%;
    }
  }
}
