@import "../../assets/styles/colors";

.bc-nav-button {
  text-decoration: none;
  display: inline-block;
  height: 100%;

  .bc-nav-button__content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //opacity: 0.5;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 1px;
    color: $black;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    //border-bottom: 4px solid transparent;
    span {
      transition: opacity 0.2s;
      opacity: 0.8;

      &.mobile {
        height: 24px;
      }
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }
}

@media (min-width: 767px) {
  .bc-navbar__content-wrapper .bc-nav-button__content {
    &:after {
      content: "";
      position: absolute;
      bottom: 22px;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(268deg, #70ceea 30%, #03c3b4);

      transition: transform 0.4s ease;
      transform-origin: right;
    }
    &:active::after {
      transform: scaleX(0);
    }
  }
  .footerContainer .bc-nav-button__content {
    color: #fff !important;
  }
}

.bc-nav-button__active {
  .bc-nav-button__content {
    &:after {
      opacity: 1;
    }
  }
}
