.globalWidth {
  box-sizing: border-box;
  margin: 0 auto;
  width: 95%;
}
@media only screen and (min-width: 576px) {
  // .globalWidth {
  //   width: 560px;
  // }
}
@media only screen and (min-width: 768px) {
  // .globalWidth {
  //   width: 752px;
  // }
}

@media only screen and (min-width: 992px) {
  .globalWidth {
    // width: 976px;
    width: 95%;
  }
}
@media only screen and (min-width: 1200px) {
  .globalWidth {
    width: 1184px;
  }
}

@media only screen and (min-width: 1600px) {
  .globalWidth {
    width: 72%;
  }
}
