@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-carousel__wrapper {
  .slide {
    user-select: none;
    background-color: $white;
  }

  .bc-carousel__controls {
    @include no-highlight;
    margin: 29px auto 0 auto;

    width: 100px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: Oswald, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 1.29px;
    text-align: center;
    color: $black;

    span {
      height: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    span:last-child {
      transform: rotate(180deg);
    }
  }

  .bc-carousel__indicator {
    background: $white;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 8px;
    border: 1px solid $mid-grey;
    border-radius: 4px;
  }

  &.mint {
    .bc-carousel__controls {
      svg {
        path {
          fill: $mint;
        }
      }
    }
    .bc-carousel__indicator {
      &.selected {
        background-color: $mint;
      }
    }
  }
  &.purple {
    .bc-carousel__controls {
      svg {
        path {
          fill: $purple;
        }
      }
    }
    .bc-carousel__indicator {
      &.selected {
        background-color: $purple;
      }
    }
  }
  &.blue {
    .bc-carousel__controls {
      svg {
        path {
          fill: $blue;
        }
      }
    }
    .bc-carousel__indicator {
      &.selected {
        background-color: $blue;
      }
    }
  }
}
