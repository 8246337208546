@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-top: 1rem;

  .tab,
  .tabUnderline {
    @include no-highlight;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    min-height: 32px;
    margin: 0 3%;
    transition: border-color 0.2s;

    &.active {
      color: $white;
    }
  }

  .tabUnderline {
    min-height: 3px;
    height: 3px;
    transform: translateY(1px);

    span {
      visibility: hidden;
    }

    &.active {
      background-image: linear-gradient(268deg, #bad635 30%, #1ec2a0);
    }
  }
}

.bottom {
  padding-top: 0;
  border-bottom: 1px solid rgba($white, 0.25);
}