.dropzoneUploadZone {
  text-align: center;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #444;
  background-color: #fafafa;
  background: linear-gradient(
    45deg,
    #f0f0f0 25%,
    #ccc 25%,
    #ccc 50%,
    #f0f0f0 50%,
    #f0f0f0 75%,
    #ccc 75%,
    #ccc
  );
  background-size: 10px 10px;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 10px;

  &.isdragUploadZone {
    font-size: 30px;
  }

  &.acceptUploadZone {
    // background-size: 12px 12px;
    background: #e3e2e2;
  }

  &.rejectUploadZone {
    border-color: #d83b01 !important;
  }

  & .zoneLabel {
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  //=== HEIGH ====
  height: 300px;

  @media screen and (max-width: 1200px) {
    height: 250px;
  }

  @media screen and (max-width: 992px) {
    height: 200px;
  }

  @media screen and (max-width: 800px) {
    height: 250px;
  }

  @media (max-width: 479px) {
    height: 200px;
  }
}

.file-size-info {
  font-size: 18px;
  text-shadow: 2px 2px 18px #fff;
  color: #121212;
  opacity: 0.4;
  text-align: left;
  // margin-bottom: 20px;
}
.file-upload-error {
  font-size: 18px;
  text-shadow: 2px 2px 18px #fff;
  color: #d83b01;
  text-align: left;
  // margin-bottom: 20px;
}
.drop-zone-label {
  font-weight: bold;
  margin: 20px 0 10px 0;
  text-transform: uppercase;
  text-align: left;
  color: #000;
}
