@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-dropdown-panel__container {
  position: relative;
  height: 100%;

  .bc-dropdown-panel__toggle-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.8;
    transition: opacity 200ms ease-out;
    cursor: pointer;

    .bc-dropdown-panel__toggle-label {
      height: 20px;
      font-family: Oswald, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 1px;
      text-align: right;
      color: $black;

      text-transform: uppercase;
    }

    .bc-dropdown-panel__toggle-icon {
      height: 20px;
      margin-left: 8px;

      svg {
        transform: scale(0.9);
      }
    }

    .bc-dropdown-panel__toggle-icon-mobile {
      svg {
        path {
          fill: $black;
        }
      }
    }

    &:hover,
    &.opened {
      opacity: 1;
    }
  }

  .bc-dropdown-panel__content-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    max-width: 300px;
    border-radius: 0 0 4px 4px;

    max-height: 40vh;
    overflow: auto;
    z-index: -1;
  }

  &.mobile {
    position: static !important;
    align-items: center;
    display: flex;
    height: 100%;

    .bc-dropdown-panel__toggle-wrapper {
      opacity: 1;
      justify-content: flex-end;
      margin-right: 4px;

      .bc-dropdown-panel__toggle-label,
      .bc-dropdown-panel__toggle-icon {
        display: none;
      }

      .bc-dropdown-panel__toggle-icon-mobile {
        display: flex;
      }
    }
  }
}

.bc-dropdown-panel__mobile-content-wrapper {
  top: 200px;
  max-width: unset;
  width: 90vw;

  // &.noSearch {
  //   top: 100px;
  // }

  // &.minimized {
  //   top: 72px;
  // }
  right: 5%;
  right: 5%;

  opacity: 1;
  position: fixed;
  display: block;
  border-radius: 12px;

  max-height: 50vh;
  overflow: auto;
  z-index: 100000;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    $bg-color
  );

  .bc-dropdown-panel__mobile-content-overlay {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 0;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      $bg-color
    );
  }
}

.bc-dropdown-panel-mobile__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $black;
  opacity: 0.68;

  z-index: 1;
}
