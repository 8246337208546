@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.bc-support-message {
  display: flex;
  align-items: center;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 10px;
  color: $white;

  span {
    margin: 0 2px;
  }

  svg {
    path {
      fill: $white;
    }
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;
    line-height: 20px;

    .bc-support-icon {
      display: none;
    }
  }
}

.dark {
  .bc-support-message {
    color: $black;

    svg {
      path {
        fill: $black;
      }
    }
  }
}
