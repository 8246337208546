.background {
  background-image: url("../../../../assets/images/2025/footer-section/footer-v1.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  aspect-ratio: auto 1440 / 87;
}

.footerContainer {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  width: 200px;
  height: 62px;
  margin-top: 35px;
  // background-image: url('../../../../assets/images/2023/logo/founder-legacy-logo.png');
  background-image: url("../../../../assets/images/2023/logo/founder-legacy-logo.svg");
  background-size: cover;
  background-position: center;
}

.linksWrap a * {
  color: #fff !important;
}

@media (max-width: 768px) {
  .linksWrap {
    display: none;
  }
  .background {
    min-height: 50px;
  }
}
