.buttonsWrapper {
  margin-left: auto;
  display: flex;
  height: 100%;
  gap: 32px;
  margin-right: 40px;

  // > a {
  //   margin-right: 24px;
  // }
}
@media (max-width: 768px) {
  .buttonsWrapper {
    margin-right: 0;
  }
}
.navButtonLikeWrap {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Oswald, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.3px;
  color: #121212;

  box-sizing: border-box;
  text-transform: uppercase;

  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.8;
  // .navButtonLike{
  //   font-size: 1.5em;
  // }
}
