@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

/* autoprefixer grid: autoplace */

.bc-tell-story-wizard {
  $transition-active-duration: 0.3s;
  min-height: calc(100vh - 107px);
  padding-left: calc(100vw - 100%);

  .wizard-step__wrapper {
    padding-top: 86px;
  }

  &.mobile {
    .step-title {
      padding-top: 50px;
    }
  }

  .step-title {
    padding-top: 80px;
    margin-bottom: 60px;
    font-family: Bureau, sans-serif;
    font-size: 80px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: -2px;
    text-align: center;
    color: $white;
    text-transform: uppercase;
  }

  .step-element {
    @include centralize;
    margin-top: 24px;
    flex: 1;

    p {
      //font-size: 16px;
      font-family: Source Sans Pro, sans-serif;
    }
  }

  .step-submit {
    @include centralize;
    margin-top: 28px;
    width: 100%;
    margin-bottom: 68px;
  }

  .bc-wizard-success {
    padding-top: 0px;

    .bc-wizard-success__icons-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .success-globe {
        //transform: scale(0.8);
      }

      //.success-marker {
      //  transform: translateY(30%);
      //}
    }

    .bc-success-title {
      @include centralize;
      font-family: Bureau, sans-serif;
      font-size: 80px;
      font-weight: 500;
      line-height: 0.87;
      letter-spacing: -2px;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 24px;
    }
    .dark .bc-success-title {
      color: #000;
    }
    p {
      font-family: Source Sans Pro, sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }

    .bc-button {
      //margin: 0 auto;
    }

    &.mobile {
      //transform: translateY(-90px);
      .bc-wizard-success__icons-wrapper {
        transform: scale(0.8);
      }

      .bc-success-title {
        transform: translateY(-60%);
        font-size: 48px;
        white-space: normal;
      }

      p {
        transform: translateY(-30px);
      }

      .bc-button {
        margin: 0 auto;
        transform: translateY(-10px);
      }
    }
  }

  .bc-step-button {
    position: relative;
    height: 100%;
    width: 100%;

    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: $black;
    @include centralize;
    @include no-highlight;
    background-color: $white;

    border: 1px solid $alto;
    border-radius: 4px;

    > div,
    svg {
      //width: 24px;
      //height: 24px;
    }

    .bc-step-button-label {
      //transform: translateX(-60%);
      font-size: 14px;
      transition: transform $transition-active-duration;
    }

    .active-icon {
      position: absolute;
      right: 6px;
      top: 6px;
      transform: scale(0);
      opacity: 0;
      transition-duration: $transition-active-duration;
      transition-property: opacity, transform;
      transform-origin: center;

      //margin-right: 4px;
      svg {
        rect {
          display: none;
        }
      }
    }

    &:hover {
      border-color: $mint;
    }

    &.active {
      border-color: $mint;
      border-width: 2px;

      .active-icon {
        transform: scale(1);
        opacity: 1;

        svg {
          rect {
            display: none;
          }

          path {
            fill: $mint;
          }
        }
      }

      .bc-step-button-label {
        //transform: translateX(0);
      }
    }
  }

  .grid-col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-template-rows: 96px;
  }

  .step-separator-or {
    height: 1px;
    background-color: $alto-light;
    margin: 2rem 0;
    position: relative;

    &:after {
      content: "URL";
      position: absolute;
      top: -8px;
      left: 50%;
      height: 16px;
      transform: translateX(-50%);
      width: 4rem;
      font-family: Source Sans Pro, sans-serif;
      font-size: 12px;
      text-align: center;
      color: $dark-grey;
      background-color: $white;
    }
  }

  .step-hint {
    text-align: left;
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    margin-top: 4px;
    margin-left: 8px;
  }

  p {
    color: $white;
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    .bc-wizard-success {
      padding-top: 20px;

      .bc-success-title {
        font-size: 70px;
      }
    }

    .step-title {
      font-size: 65px;
    }
  }
}

.bc-tell-story-wizard__background {
  background-image: url("../../assets/images/2025/background-other-pgs.jpg");
  // background-image: url('../../assets/images/2023/nominated/nominated-background@3x.jpg');
  // background-image: image-set(
  //   url('../../assets/images/2023/nominated/nominated-background@1x.jpg') 1x,
  //   url('../../assets/images/2023/nominated/nominated-background@2x.jpg') 2x,
  //   url('../../assets/images/2023/nominated/nominated-background@3x.jpg') 3x);

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.bc-tell-story-wizard__background_overlay {
  position: absolute;
  background-image: url("../../assets/images/mmm/stroke.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  opacity: 0;
  inset: 0;
  //transform: translateY(-50%);
  pointer-events: none;
}

.files-drop-zones {
  display: flex;
  gap: 20px;
}

.files-drop-item {
  flex: 1;
}

.cover-image {
  background-color: #fafafa;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 10px;
  border: 2px solid #eee;
  overflow: hidden;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & > .close-preview {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #000;
    border-radius: 50%;
    // opacity: 0;
    transition: opacity 0.1s linear;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(239, 238, 234, 0.8);
  }

  //=== HEIGH ====
  height: 300px;

  @media (max-width: 1200px) {
    height: 250px;
  }

  @media (max-width: 992px) {
    height: 200px;
  }

  @media (max-width: 800px) {
    height: 250px;
  }

  @media (max-width: 479px) {
    height: 200px;
  }
}

.step-double-col-row {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: left;
  width: 100%;

  @media (max-width: 768px) {
    display: block;
  }
}

.dark {
  .bc-tell-story-wizard p,
  .bc-success-title,
  .step-title {
    color: #000 !important;
  }
}
