.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-heading {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  
  .not-found-text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found-image {
    max-width: 400px;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    font-size: 1.2rem;
    color: #000;
    text-decoration: none;
    border: 2px solid #000;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #000;
    color: #fff;
  }
  