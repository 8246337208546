$white: #ffffff;
$green: #00ac67;
$red: #ee0000;
$light-green: #d4f5cf;
$light-red: #fedad3;
$grey: #cdcdd5;
$light-grey: #f7f7f8;
//$mid-grey: #e9e9ef;
$mid-grey: #f2f2f2;
$alto: #f0f0f0;
$alto-light: #e0e0e0;
$dark-grey: #898989;
$orange: #f2874f;
$bg-color-dark: #b49e7a;
$peach: #ffd6c2;

$sea: #8ee5fe;
$mint: #17a0a1;
$topb: #53c3ba;
$black: #121212;
$emperor: #545454;
$black-russian: #000316;
$bg-color: #fff;
$purple: #db007b;
//$blue: #39d6f7;
$blue: #52cad9;
$wave: #17a0a1;
$error: #e81253;
$orange: #f1874f;

.color-black {
  color: $black;
}
.color-purple {
  color: $purple;
  svg {
    path {
      fill: $purple;
    }
  }
}
