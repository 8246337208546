@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-footer {
  @include centralize;
  flex-direction: column;
  position: relative;
  width: 70%;
  height: 107px;
  //background-color: $white;
  border-top: 1px solid $alto-light;
  z-index: 0;
  margin: 0 15%;
}
.bc-footer.dark{
  border: none;
}