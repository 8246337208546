@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.bc-search-input {
  position: relative;
  width: 100%;

  .bc-search-input__users-result-wrapper {
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 1;
    max-height: 360px;
    overflow: auto;
    border: 1px solid $mint;
    border-radius: 0 0 4px 4px;

    .bc-search-input__users-result-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      padding: 12px;
      cursor: pointer;

      font-family: Source Sans Pro, sans-serif;

      h5 {
        margin: 0;
      }

      > div {
        font-size: 12px;
        color: $emperor;
      }

      &:hover {
        background-color: $light-grey;
      }
    }
  }

  .search-loader {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 17px;
    opacity: 0.5;

    svg {
      circle {
        fill: none;
        stroke: $dark-grey;
        stroke-width: 2px;
        stroke-dasharray: 14 4;
        transform-origin: center;
        animation: spinner;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }

    @keyframes spinner {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
