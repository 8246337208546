.logoContainer {
  width: 152px;
  height: 48px;
  margin-top: 12px;
  background-image: url("../../assets/images/2025/logo/founders-day-logo-2025.png");
  //   background-image: url("../../assets/images/2023/logo/founder-legacy-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .logoContainer {
    // height: 57px;
    margin-top: 8%;
    background-position: inherit;
  }
}
