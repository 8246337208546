@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";

.bc-multi-links {
  width: 100%;

  .bc-multi-link__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;

    &:hover:not(:first-child) {
      .bc-multi-link__item-close {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .bc-multi-link__item-close {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    position: absolute;
    right: 14px;
    top: 20px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    background-color: #000;
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $white;
      }
    }
  }

  .bc-multi-link_item_add {
    @include centralize;
    @include no-highlight;
    justify-content: flex-start;

    &:hover {
      filter: brightness(90%);
    }

    cursor: pointer;

    svg path {
      fill: #000;
    }

    > span {
      height: 16px;

      &:last-child {
        margin-left: 4px;
        height: 18px;
        font-family: Source Sans Pro, sans-serif;
        font-size: 14px;
        color: #000;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .bc-multi-link__item-error {
    width: 100%;
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: $error;

    height: 20px;
    margin: 2px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      @include centralize;

      &:first-child {
        margin-right: 4px;
      }
    }
  }

  p {
    font-family: Source Sans Pro, sans-serif;
    text-align: left;
    opacity: 0.4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
  }
}
