.list {
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;

  grid-column-gap: 24px;

  grid-template-columns: repeat(3, minmax(360px, 1fr));
  grid-template-rows: repeat(50, auto);

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    grid-template-rows: repeat(50, auto);
    grid-column-gap: 24px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(260px, 1fr));
    grid-template-rows: repeat(50, auto);
    grid-column-gap: 24px;
  }
}

.bc-no-stories-info {
  margin-top: 3rem;
}
