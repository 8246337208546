@import '../../assets/styles/colors';

.bc-popup__title-wrapper {
  margin-bottom: 15px;
  //height: 80px;
  font-family: Bureau, sans-serif;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: transparent;
  background-image: linear-gradient(269deg, #f2874f 44%, #e50594);
  background-clip: text;
  text-transform: uppercase;

  &.mobile {
    font-size: 44px;
    margin-bottom: 0;
  }
}

.bc-popup-close {
  position: absolute;
  right: 20px;
  top: 20px;

  cursor: pointer;
}

.bc-popup__content {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: $black;
  padding: 12px 0 0 0;
  //background-image: image-set(
  //  url('../../assets/images/mmm/first-section-overlay/first-image.webp') 1x,
  //  url('../../assets/images/mmm/first-section-overlay/first-image@2x.webp') 2x,
  //  url('../../assets/images/mmm/first-section-overlay/first-image@3x.webp') 3x);
  background-position: center;

  ul {
    list-style: none;
    padding: 0;

    width: 50%;
    text-align: left;
    margin: 0 auto;

    li {
      display: flex;
      align-items: center;

      .icon {
        width: 16px;
        height: 16px;
      }
      .label {
        margin-left: 12px;
        white-space: nowrap;
      }
    }
  }
}

.bc-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 56px;

  &.mobile {
    margin-top: 23px;

    flex-direction: column;

    .bc-button:last-child {
      margin: 24px;
    }
  }

  //.bc-button {
  //  text-transform: uppercase;
  //}
}
